import { Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";

import { Attachment, WebSocketFeedback, WebSocketMessage } from "../models/webSocketMessage";
import { WebSocketService } from "./web-socket.service";
import { LanguageService } from "./language.service";
import { HttpService } from "./http.service";
import { ConfigService } from "./config.service";
import { MessageDisplayFormat } from "../models/messageDisplayFormat";
import { VisibilityService } from "./visibility.service";
import { EventService } from "./event.service";
import { AttachmentDto } from "../dtos/attachmentDto";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
    messageIndex: number | null = null;
    messages: MessageDisplayFormat[] = []

    constructor(
        private config: ConfigService,
        private event: EventService,
        private http: HttpService,
        private language: LanguageService,
        private socket: WebSocketService,
        private visibility: VisibilityService) { }

    // getters
    getMessages(): MessageDisplayFormat[] {
        return this.messages
    }

    getMessageIndex(): number | null {
        return this.messageIndex ?? null;
    }

    getMessage(id: string) {
        return this.messages.find(m => m.id === id)
    }

    // setters
    setMessage (author: string, message: WebSocketMessage) {
        // Prepare AI attachments to display
        if (author == 'ai') {
            if (!message.hasOwnProperty("attachments")) {
                message.attachments = [];
            }

            const attachments = message.attachments;
            message.attachments = [];

            if (attachments && attachments.length > 0) {
                message.attachments = this.createAttachmentDisplay(attachments);
            }

            message.content = message.content.replaceAll(/\[([^\]]+)\]\((http:\/\/[^\s]+|https:\/\/[^\s]+)\)/g, '<a href="$2" target="_blank">$1</a>');
        }

        let m = this.createMessageDisplay(author, message)
        this.messages.push(m);

        // TODO remove from here
        if (author == "ai") {
            this.visibility.hideComponent("avatar-loader");
        } else if (author == "client") {
            this.visibility.showComponent("avatar-loader");
        }

        this.event.scrollToBottomEvent.emit();
    }

    setMessageFeedback (index: number, feedback: boolean, feedbackContent?: string, feedbackReason?: string) {
        const message = this.findMessageByIndex(index);
        if (message) {
            message.feedback = feedback;
            this.messages[index] = message;

            const feedbackWS: WebSocketFeedback = {
                type: "feedback",
                feedback_id: message.id,
                feedback_status: message.feedback,
                feedback_message: feedbackContent ?? "",
                feedback_reason: feedbackReason ?? ""
            }

            this.sendFeedback(feedbackWS).then();
        }
    }

    setMessageIndex(index: number) {
        this.messageIndex = index;
    }

    createMessageDisplay(author: string, message: WebSocketMessage) {
        let messageObj: MessageDisplayFormat = {
            id: message.id ?? "",
            author: author,
            content: message.content,
            language: message.language,
            direction: this.language.getLanguage(message.language).direction,
            attachments: message.attachments,
            audioAnswerUrl: message.audioAnswer
        }

        return messageObj;
    }

    clearMessages () {
        this.messages = [];
    }

    findMessageByIndex(index: number) {
        return this.messages.find((_, i) => i === index);
    }

    async editMessage(index: number, content: string) {
        const message = this.findMessageByIndex(index);
        if (message) {
            message.content = content;
            this.messages[index] = message;
            this.messages.splice(index + 1);

            const messageWS: WebSocketMessage = {
                type: "text",
                content: content,
                language: this.language.getSelectedLanguage()?.locale
            }

            this.visibility.showComponent("avatar-loader");

            this.event.scrollToBottomEvent.emit();
            await this.submitConversationRequest(messageWS);
        }
    }

    createAttachmentDisplay (files: any) {
        const attachments = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const newAttachment = new Attachment(file.type, file.url,file.name ?? null);
            attachments.push(newAttachment);
        }

        return attachments;
    }

    async createConversationRequest (type: string, currentInputValue: string, audioId?: string, files?: AttachmentDto[]) {
        this.visibility.showComponent("avatar-conversation")

        const message: WebSocketMessage = {
            type: type,
            content: currentInputValue,
            language: this.language.getSelectedLanguage()?.locale
        }

        // Prepare client attachments to display
        message.attachments = [];
        if (files && files.length !== 0) {
            message.attachments = this.createAttachmentDisplay(files);
        }

        if (type === "audio") {
            message.content = audioId ?? "not found";
        } else if (type === "text") {
            this.setMessage("client", message);
        }

        try {
            await this.submitConversationRequest(message);
        } catch (e: any) {
            console.log('Error while submit conversation request: ' + e)
        }
    }

    // TODO setup file for BE
    /*setUpFile (file: AttachmentDto): Attachment {
        const type = file.type;
        const name = file.name;
        const readableName = name.replace(/[-_]/g, ' ').replace(/\.[^/.]+$/, '');
        if (type === 'image') {
            message.content = `Here is a scan of my ${readableName}: ${name}`;
        } else if (type === 'video') {
            message.content = `Here is my ${readableName} video: ${name}`;
        } else if (type === 'document') {
            message.content = `Here is my ${readableName} document: ${name}`;
        }
        return { type: type, url: file.src };
    }*/

    // events
    async sendFeedback (feedback: WebSocketFeedback) {
        try {
            this.socket.sendFeedback(feedback);
        } catch (e: any) {
            throw new Error("Could not push feedback");
        }
    }

    async submitConversationRequest (data: WebSocketMessage) {
        try {
            this.socket.sendMessage(data);
        } catch (e: any) {
            if (e instanceof ProgressEvent || e.status === 0) {
                const lang = this.language.getSelectedLanguage().locale;

                const message: WebSocketMessage = {
                    id: "lost-net",
                    type: 'text',
                    content: this.language.getDesignTranslation(lang).typography.lostNet,
                    language: lang
                }

                this.setMessage("ai", message)
                return;
            }
            throw e;
        }
    }
    async submitAudioData (audioData: File) {
        const formData = new FormData();
        formData.append("audio", audioData);

        try {
            return await lastValueFrom(
                this.http
                .setHost(`${this.config.getHttpScheme()}://${this.config.getHost()}/agent/${this.config.getProjectId()}/audio`)
                .setMethod("POST")
                .setHeaders({
                    "Authorization": `Bearer ${this.config.getAnonymousId()}`
                })
                .setContent(formData)
                .create()
            );
        } catch (e: any) {
            throw new Error(`could not submit audio data: ${e}`)
        }
    }
}
